import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';
import Footer from 'common/source/containers/Footer';
import Banner from 'common/source/containers/BannerCatalogCategory';
import CatalogCategoryBody from 'common/source/containers/CatalogCategoryBody';

import SEO from '../../components/SEO';
import config from '../../../data/SiteConfig';

class CatalogoPortable extends React.Component {
  render() {
    const { data } = this.props;
    const { page, products, solutions } = data;
    const { frontmatter } = page;
    const allProducts = products.edges.map(edge => edge.node);
    const allSolutions = solutions.edges.map(edge => edge.node);
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO
            pageTitle={`Catálogo ${frontmatter.title} | ${config.siteTitle}`}
            path={`/${frontmatter.slug}`}
            pageNode={page}
          />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background='blue' />
            <Banner
              cover={frontmatter.cover}
              title={frontmatter.title}
              subtitle={frontmatter.subtitle || frontmatter.description}
            />
            <CatalogCategoryBody
              node={page}
              products={allProducts}
              solutions={allSolutions}
            />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default CatalogoPortable;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CatalogoPortableQuery {
    page: markdownRemark(frontmatter: { slug: { eq: "catalogo/portatil" } }) {
      html
      timeToRead
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        title
        description
        cover
        thumbnail
      }
    }
    products: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "PRODUCT" }
          published: { eq: true }
          catalog: { in: ["catalogo/portatil"] }
        }
      }
      sort: { order: ASC, fields: [frontmatter___storage] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            sku
            title
            slug
            description
            storage
            catalog
            tags
            featured
            price
            price_ref
            thumbnail
            cover
          }
        }
      }
    }
    solutions: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "SOLUTION" }
          related: { in: ["catalogo/portatil"] }
        }
      }
      sort: { order: ASC, fields: [frontmatter___featured] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            slug
            title
            cover
            thumbnail
            tags
          }
        }
      }
    }
  }
`;
